import { inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate, CanActivateChild {
  private router = inject(Router);

  canActivateChild() {
    return this.checkToken();
  }

  canActivate() {
    return this.checkToken();
  }

  private checkToken() {
    const authToken = localStorage.getItem('mealToken');
    if (authToken && !this.tokenExpired(authToken)) {
      return true;
    } else {
      localStorage.removeItem('mealToken');
      return this.router.parseUrl('/login');
    }
  }

  private tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
