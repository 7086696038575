import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const authToken: string = localStorage.getItem('mealToken');

    if (
      req.url !== '/api/user/login' &&
      req.url !== '/api/user/register' &&
      authToken
    ) {
      req = req.clone({
        setHeaders: {
          authorization: `mealToken ${localStorage.getItem('mealToken')}`,
        },
      });
    }

    return next.handle(req);
  }
}
