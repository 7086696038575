import { Routes } from '@angular/router';
import { LoginGuard } from './login-guard';
import { importProvidersFrom } from '@angular/core';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.routes').then((m) => m.loggedInRoutes),
    canActivateChild: [LoginGuard],
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.page').then((m) => m.LoginPage),
    providers: [
      importProvidersFrom(SocialLoginModule),
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          onError: (err) => {
            console.error(err);
          },
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                '935443624817-1p1ctb2medba1toa28l98li6rcespjl1.apps.googleusercontent.com',
                {
                  oneTapEnabled: false,
                },
              ),
            },
          ],
        } as SocialAuthServiceConfig,
      },
    ],
  },
];
